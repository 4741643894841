@import "../../values/Color.css";

.home {

}

.home-header {

}

.home-background.home-bg-img {
    background: url("../../assets/Reedwell_Arts_Banner.png") no-repeat top center fixed;
    background-size: cover;
    max-width: 100%;
    min-height: 100vh;
    
}

.home-background {
    background-color: var(--primary);
    text-align: center;
    color: var(--accentDark);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 32px;
}

.home-background h1 {
    margin-left: 10vw;
    margin-right: 10vw;
}

.home-background p {
    margin-left: 10vw;
    margin-right: 10vw;
}

.home-background2 {
    background-color: var(--primary);
}

.home-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}