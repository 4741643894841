nav {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: fixed;
    margin: 0 auto;
    left: 0px;
    right: 0px;
    top: 0;
}

.menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    background-color: var(--accentDark);
}

.menu li {
    margin: 0 15px;
}

.menu a {
    text-decoration: none;
    color: var(--tertiary);
    padding: 10px 20px;
    display: block;
}

.menu a:hover {
    background-color: var(--accentLight);
    border-radius: 4px;
}
